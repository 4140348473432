import React from 'react'

import Layout from "../components/Layout"


export default function About() {
    return (

        <Layout>
        <div>
            <h1>ABout Page</h1>
            <p>Lorem Ipsum</p>
        </div>
        </Layout>
    )
}
